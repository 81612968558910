import { Divider, Typography } from "@suraasa/placebo-ui"
import twitterVerifiedBadge from "assets/twitter-verified-badge.png"
import { format } from "date-fns"
import React, { useContext } from "react"
import ProfileContext from "views/teacher/profileContext"

import { ReferenceCard } from "../../Reference/Utils"

const Qualifications = () => {
  const {
    academics: {
      qualifications: { data },
    },
  } = useContext(ProfileContext)

  return (
    <>
      {data.map((qualification, index) => (
        <div className="ml-2 pt-2" key={qualification.id || index}>
          <div className="pb-2">
            <div className="mb-0.5 flex items-center gap-0.5">
              <Typography variant="strongSmallBody">
                {qualification.name}
              </Typography>
              {qualification.isVerified && (
                <img
                  alt="✅"
                  height="24px"
                  src={twitterVerifiedBadge}
                  width="24px"
                />
              )}
            </div>

            {qualification.qualificationLevel && (
              <Typography className="mb-0.25" variant="smallBody">
                {qualification.qualificationLevel.name}
              </Typography>
            )}

            <Typography className="mb-0.25" variant="smallBody">
              {qualification.organisationName}
            </Typography>

            {qualification.startDate && qualification.endDate && (
              <Typography variant="smallBody">
                {[
                  format(new Date(qualification.startDate), "MMMM yyyy"),
                  format(new Date(qualification.endDate), "MMMM yyyy"),
                ].join(" - ")}
              </Typography>
            )}
            {qualification.references?.map((ref, ind) => (
              <ReferenceCard key={ind} data={ref} />
            ))}
          </div>
          {index < data.length - 1 && (
            <Divider color="onSurface.200" weight="light" />
          )}
        </div>
      ))}
    </>
  )
}

export default Qualifications
