import { Button, Divider, Radio, theme, Typography } from "@suraasa/placebo-ui"
import {
  generateReferenceRelationshipChoices,
  Option,
  Personalisation,
  QuestionType,
  Reference,
  Section,
  UserQuestionnaire,
  UserReferenceRelationship,
} from "api/resources/references/types"
import ReferenceIcon from "assets/icons/reference_icon.svg"
import clsx from "clsx"
import { Check } from "lucide-react"
import React, { useContext, useState } from "react"
import ProfileContext from "views/teacher/profileContext"

import AnalysisSheet from "./AnalysisSheet"

const ReferenceCard: React.FC<{ data: Reference }> = ({ data }) => {
  const { profile } = useContext(ProfileContext)
  return (
    <div className="mt-1 rounded-lg border border-onSurface-200 p-1.5">
      <div className="flex items-start">
        <div className="rounded-full bg-success-50 p-0.75">
          <img
            color={theme.colors.success[600]}
            src={ReferenceIcon}
            alt="Reference"
            height="10px"
            width="12px"
          />
        </div>

        <div className="ml-0.75 grow">
          <div className="flex items-center justify-between">
            <Typography variant="smallBody" color="onSurface.600">
              Reference
            </Typography>
          </div>

          <div className="mt-2">
            <Typography variant="strongSmallBody" color="onSurface.900">
              {data.referrer.name}
            </Typography>

            <Typography
              className="mt-0.75"
              variant="smallBody"
              color="onSurface.600"
            >
              {getRelationshipLabel({
                referrerName: data.referrer.name,
                username: profile.user.firstName,
                relationshipValue: data.relationship,
              })}
            </Typography>
          </div>
        </div>
      </div>
      <ViewAnalysisCard
        rating={data.userQuestionnaire.averageScore}
        reference={data}
      />
    </div>
  )
}

const ViewAnalysisCard: React.FC<{ rating: string; reference: Reference }> = ({
  rating,
  reference,
}) => {
  const [analysisSheetOpen, setAnalysisSheetOpen] = useState(false)
  return (
    <>
      {analysisSheetOpen && (
        <AnalysisSheet
          reference={reference}
          openSheet={analysisSheetOpen}
          setOpenSheet={setAnalysisSheetOpen}
        />
      )}

      <div className="mt-2 rounded-lg bg-surface-100 p-1.5    ">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Typography color="onSurface.700" variant="title3">
              {parseFloat(rating).toFixed(1)}
            </Typography>
            <Typography
              color="onSurface.500"
              className="ml-1"
              variant="strongSmallBody"
            >
              Overall Rating
            </Typography>
          </div>
          <Button
            onClick={() => {
              setAnalysisSheetOpen(true)
            }}
            variant="text"
          >
            View Analysis
          </Button>
        </div>
      </div>
    </>
  )
}

const Legend = () => {
  const options = ["A", "B", "C", "D", "E", "N/A"]
  return (
    <div className="mb-3 flex items-center justify-between">
      <div></div>

      <div className="flex items-center justify-between gap-4">
        {options.map((opt, i) => (
          <Typography
            key={opt}
            className="size-2.25"
            variant="strong"
            textAlign="center"
          >
            {opt}
          </Typography>
        ))}
      </div>
    </div>
  )
}

const replacePlaceholder = (text: string, name: string) =>
  text.replace(/__referee_name__/gi, name ?? "the teacher")

const Question = (props: { text: string; refereeName: string }) => (
  <Typography variant="strong">
    {replacePlaceholder(props.text, props.refereeName)}
  </Typography>
)

const QuestionsLayout = ({
  data,
  personalisation,
}: {
  data: Section
  personalisation: Personalisation
}) => {
  return (
    <div className="divide-y divide-onSurface-200">
      {data.questions.map((question, index) => (
        <div
          key={index}
          className={clsx("flex justify-between gap-2 py-2", {
            "flex-col":
              question.options.length === 2 ||
              question.questionType === QuestionType.TEXT,
            "items-center": question.options.length > 5,
          })}
        >
          <Question
            text={question.name}
            refereeName={personalisation.refereeName}
          />

          {question.questionType === QuestionType.TEXT && (
            <Typography color="onSurface.700" variant="body">
              {question.response}
            </Typography>
          )}

          {question.options.length === 2 && (
            <OptionSelector
              options={question.options}
              response={question.response}
            />
          )}

          {question.options.length > 5 && (
            <div className="flex items-center justify-between gap-4">
              {question.options.map((opt, i) => (
                <Radio
                  readOnly
                  key={i}
                  checked={question.response === opt.value}
                  value={opt.value}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

interface OptionSelectorProps {
  options: Option[]
  response: number
}

function OptionSelector({ options, response }: OptionSelectorProps) {
  const getLayoutClass = (length: number) => {
    return clsx({
      "grid grid-cols-2 gap-1.5": length === 2,
      "flex flex-col gap-1.5": length === 3,
      "flex gap-1": length !== 2 && length !== 3,
    })
  }
  const getOptionClass = (length: number) => {
    return clsx("relative flex items-center rounded-lg border", {
      "px-1.5 py-1.5 items-center justify-start": length <= 3,
      "px-1.5 py-1.25 flex-col items-center": length > 3,
    })
  }

  const isNotApplicable = options.some(
    opt => opt.title === "N/A" && opt.value === response
  )

  if (isNotApplicable) {
    return (
      <div className="w-full rounded-lg border border-onSurface-100 bg-surface-50 px-1.5 py-2">
        <Typography variant="strongSmallBody" color="onSurface.600">
          Not Applicable
        </Typography>
      </div>
    )
  }

  return (
    <div className={getLayoutClass(options.length)}>
      {options.map(
        (option, index) =>
          option.title !== "N/A" && (
            <div
              key={index}
              className={clsx(getOptionClass(options.length), {
                "border-2 border-primary-500": option.value === response,
                "border-onSurface-200": option.value !== response,
              })}
            >
              {options.length <= 3 ? (
                <>
                  <div
                    className={clsx("mr-1.25", {
                      "size-3 rounded-full border border-onSurface-200 bg-onSurface-50":
                        option.value !== response,
                      "items-center rounded-full border bg-primary-500 p-0.25":
                        option.value === response,
                    })}
                  >
                    {option.value === response && (
                      <Check size="20" color={theme.colors.surface[50]} />
                    )}
                  </div>
                  <Typography
                    variant="strongSmallBody"
                    color="onSurface.900"
                    textAlign="center"
                  >
                    {option.title}
                  </Typography>
                </>
              ) : (
                <>
                  <Radio
                    checked={option.value === response}
                    className="mb-1.5"
                    value={option.title}
                  />
                  <Typography
                    variant="strongSmallBody"
                    color="onSurface.900"
                    textAlign="center"
                  >
                    {option.title}
                  </Typography>
                </>
              )}
            </div>
          )
      )}
    </div>
  )
}
const AnalysisSection: React.FC<{ data: UserQuestionnaire | undefined }> = ({
  data,
}) => {
  return (
    <>
      <div className="md:hidden">
        {data?.sections.slice(1).map((section, index) => (
          <div key={index} className="flex-col items-center">
            <Typography variant="title2" className="mt-3">
              {section.name}
            </Typography>

            <div>
              {section.questions.map((question, index) => (
                <div key={index} className="flex flex-col gap-1 py-2">
                  <Question
                    text={question.name}
                    refereeName={data?.personalisation.refereeName}
                  />

                  {question.questionType === QuestionType.TEXT ? (
                    <Typography color="onSurface.700" variant="body">
                      {question.response}
                    </Typography>
                  ) : (
                    <OptionSelector
                      options={question.options}
                      response={question.response}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-4 hidden md:block">
        {data?.sections.slice(1).map((section, index) => (
          <div key={index} className="flex-col items-center bg-white px-2.5">
            <Typography variant="title2" color="onSurface.900">
              {section.name}
            </Typography>

            <Legend />

            <QuestionsLayout
              data={section}
              personalisation={data.personalisation}
            />

            {index < data.sections.length - 1 && (
              <Divider
                color="onSurface.200"
                weight="heavy"
                className="mb-4 mt-2"
              />
            )}
          </div>
        ))}
      </div>
    </>
  )
}

function getRelationshipLabel({
  referrerName,
  username,
  relationshipValue,
}: {
  referrerName: string
  username: string
  relationshipValue: UserReferenceRelationship
}): string {
  const choice = generateReferenceRelationshipChoices({
    username: username,
    referrerName: referrerName.split(" ")[0],
  }).find(choice => choice.value === relationshipValue)

  return choice?.label || ""
}

export {
  AnalysisSection,
  getRelationshipLabel,
  ReferenceCard,
  ViewAnalysisCard,
}
