import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNexusUrl = getServiceURL(ServicePrefix.nexus)

export const urls = validateUrls({
  list: uuid => getNexusUrl(`/v1/references/users/${uuid}/public/`),
  retrieve: id => `/v1/references/users/${id}/`,
})
