import axios from "api/axios"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Reference } from "./types"
import { urls } from "./urls"

export default {
  listReferences: async ({
    urlParams: { uuid },
  }: ConfigWithURLParams): Promise<APIResponse<Reference[]>> => {
    try {
      const res = await axios.get(urls.list(uuid))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  retrieveReference: async ({
    urlParams: { referenceId },
  }: ConfigWithURLParams): Promise<APIResponse<Reference>> => {
    try {
      const res = await axios.get(urls.retrieve(referenceId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
