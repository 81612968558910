import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { Reference } from "api/resources/references/types"
import { format } from "date-fns"
import React, { useContext } from "react"
import ProfileContext from "views/teacher/profileContext"

import { Avatar } from "@/components/Avatar"
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/Sheet"

import { getEmploymentTypeDisplay } from "../WorkExperience/utils"
import { AnalysisSection, getRelationshipLabel } from "./Utils"

type Props = {
  reference: Reference
  openSheet: boolean
  setOpenSheet: React.Dispatch<React.SetStateAction<boolean>>
}
const AnalysisSheet = ({ openSheet, setOpenSheet, reference }: Props) => {
  const criteriaData = [
    {
      grade: "A",
      description: "Innovator and Inspiration",
      points: "5 points",
    },
    { grade: "B", description: "Teaching Excellence", points: "4 points" },
    { grade: "C", description: "Student Engagement", points: "3 points" },
    {
      grade: "D",
      description: "Professional Development",
      points: "2 points",
    },
    { grade: "E", description: "Leadership Abilities", points: "1 point" },
  ]

  const { profile } = useContext(ProfileContext)

  const { data, isLoading } = useQuery({
    queryKey: ["reference", reference.id],
    queryFn: async () => {
      return api.references.retrieveReference({
        urlParams: { referenceId: reference.id },
      })
    },
  })

  const fullName = [profile.user.firstName, profile.user.lastName]
    .filter(Boolean)
    .join(" ")

  console.log(data)

  return (
    <>
      <Sheet open={openSheet} onOpenChange={setOpenSheet}>
        <SheetContent side="bottom" height={90}>
          <SheetHeader>
            <SheetTitle>Profile</SheetTitle>
          </SheetHeader>
          <SheetBody>
            {isLoading ? (
              <div className="flex h-[80vh] items-center justify-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="mb-1 flex flex-col rounded-xl border-surface-200 bg-surface-100 p-2 sm:p-3">
                  <div className="mb-1 flex flex-row">
                    <Avatar
                      src={profile.picture}
                      name={fullName}
                      className="size-5 bg-white"
                    />
                    <div className="flex flex-col">
                      <Typography
                        className="ml-1"
                        variant="title2"
                        color="onSurface.900"
                      >
                        {fullName}
                      </Typography>
                      {data?.relationship && <Typography variant="strongSmallBody" color="onSurface.600" className="mb-2 ml-1">
                        {getRelationshipLabel({ referrerName: data.referrer.name, username: profile.user.firstName, relationshipValue: data?.relationship })}
                      </Typography>}
                    </div>

                  </div>

                  <Introduction data={data} />
                </div>
                <div className="flex items-center justify-between rounded-t-xl bg-onSurface-900 px-3 py-2">
                  <Typography color="surface.50" variant="title2">
                    Overall Rating
                  </Typography>
                  <div className="flex items-baseline">
                    <Typography
                      color="surface.50"
                      variant="largeTitle"
                      className="leading-none"
                    >
                      {parseFloat(
                        data?.userQuestionnaire?.averageScore ?? ""
                      ).toFixed(1)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="surface.100"
                      className="ml-0.5 leading-none"
                    >
                      /5
                    </Typography>
                  </div>
                </div>
                <div className="flex-col items-center space-y-1 rounded-b-xl bg-onSurface-700 px-2.5 py-3">
                  {criteriaData.map((item, index) => (
                    <div
                      className="flex items-center justify-between"
                      key={index}
                    >
                      <div className="flex items-center">
                        <Typography
                          className="mr-1.5"
                          color="onSurface.50"
                          variant="strongSmallBody"
                        >
                          {item.grade}
                        </Typography>
                        <Typography
                          variant="strongSmallBody"
                          color="surface.50"
                        >
                          {item.description}
                        </Typography>
                      </div>
                      <Typography color="onSurface.100">
                        {item.points}
                      </Typography>
                    </div>
                  ))}
                </div>
                <AnalysisSection data={data?.userQuestionnaire} />
              </div>
            )}
          </SheetBody>
        </SheetContent>
      </Sheet>
    </>
  )
}

const Introduction: React.FC<{ data?: Reference }> = ({ data }) => {
  if (!data) return null

  return (
    <div className="flex flex-col items-start md:flex-row md:gap-4">
      <div className="mb-4">
        <Typography
          variant="strongSmallBody"
          className="mb-1.5"
          color="onSurface.500"
        >
          Referred By
        </Typography>
        <div className="space-y-0.5">
          <Typography color="onSurface.900" variant="title4">
            {data.referrer.name}
          </Typography>
          <Typography color="onSurface.600" variant="smallBody">
            {data.referrer.position}
          </Typography>
          <Typography color="onSurface.600" variant="smallBody">
            {data.organisationName}
          </Typography>
          <div className="flex-col md:flex">
            <Typography color="onSurface.600" variant="smallBody">
              +{data.referrer.phoneNumber.code}{" "}
              {data.referrer.phoneNumber.number}
            </Typography>
            <Typography color="onSurface.600" variant="smallBody">
              {data.referrer.email}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <Typography
          className="mb-1.5"
          color="onSurface.500"
          variant="strongSmallBody"
        >
          Referred for Experience
        </Typography>
        <div className="space-y-0.5">
          <Typography color="onSurface.700" variant="title4">
            {data.userPosition}
          </Typography>
          {data.source?.employmentType && (
            <Typography color="onSurface.600" variant="smallBody">
              {[
                getEmploymentTypeDisplay(data.source.employmentType),
                data.source.teachingLevel?.name,
              ]
                .filter(Boolean)
                .join(", ")}
            </Typography>
          )}
          <Typography color="onSurface.600" variant="smallBody">
            {[
              data.organisationName,
              [data.source?.state?.name, data.source?.country?.name]
                .filter(Boolean)
                .join(", "),
            ]
              .filter(Boolean)
              .join(" • ")}
          </Typography>

          {data.startDate && (
            <Typography variant="smallBody" color="onSurface.600">
              {[
                format(new Date(data.startDate), "MMMM yyyy"),
                data.endDate ? format(new Date(data.endDate), "MMMM yyyy") : "Present",
              ].join(" - ")}
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}

export default AnalysisSheet
