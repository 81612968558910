import { Profile, TeachingMode } from "api/resources/profile/types"

export const RTL_LANGUAGE_CODES = ["ar"]
export const DEFAULT_LANGUAGE_CODE = "en"
export const USER_TYPE = "School"

export const PAGE_SIZE = 10

export const AUTHORIZATION_HEADER_PREFIX = "Bearer"
export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
  schoolId: "schoolId",
  userType: "userType",
  schoolName: "schoolName",
}

export const PRODUCT = {
  learning: 1,
  school: 2,
} as const

export enum ServicePrefix {
  solis = "solis",
  gravity = "gravity",
  nebula = "nebula",
  kepler = "kepler",
  doppler = "doppler",
  nexus = "nexus",
}

export enum LanguageProficiency {
  ELEMENTARY = "elementary",
  LIMITED_WORKING = "limited_working",
  PROFESSIONAL_WORKING = "professional_working",
  FULL_PROFESSIONAL = "full_professional",
  NATIVE = "native",
}

export const acceptedImageTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
]
/**
 * Values are in minutes
 */
export const DURATION_HOURS = [
  { label: "0 hours", value: 0 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 2 * 60 },
  { label: "3 hours", value: 3 * 60 },
]

export const DURATION_MINUTES = [
  { label: "0 minutes", value: 0 },
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "45 minutes", value: 45 },
]

export const teachingModeChoices = [
  { label: "Online", value: TeachingMode.ONLINE },
  {
    label: "Classroom Programme (offline)",
    value: TeachingMode.CLASSROOM,
  },
  {
    label: "Blended (Online & Classroom)",
    value: TeachingMode.BLENDED,
  },
]

export const weekDays = [
  { id: 7, day: "Sunday" },
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
]

export const DEFAULT_PROFILE_COVER = "/assets/defaultCover.png"
export const DEFAULT_GALLERY_PIC = "/assets/defaultGalleryImage.jpg"

export const tabsProfile = {
  overview: "Overview",
  gallery: "Gallery",
  jobs: "Jobs",
  perksAndAmenities: "Perks and Amenities",
}

export const assessmentDurationOptions = [
  { label: "5 minutes", value: 5 * 60 },
  { label: "10 minutes", value: 10 * 60 },
  { label: "15 minutes", value: 15 * 60 },
  { label: "20 minutes", value: 20 * 60 },
  { label: "25 minutes", value: 25 * 60 },
  { label: "30 minutes", value: 30 * 60 },
  { label: "35 minutes", value: 35 * 60 },
  { label: "40 minutes", value: 40 * 60 },
  { label: "45 minutes", value: 45 * 60 },
  { label: "50 minutes", value: 50 * 60 },
  { label: "55 minutes", value: 55 * 60 },
  { label: "60 minutes", value: 60 * 60 },
]

export const PERK_CHAR_LIMIT = 50
export const AMENITIES_CHAR_LIMIT = 50

export type NotificationAction =
  | {
      name: "APPLICATION_RECEIVED"
      data: {
        jobId: number
      }
    }
  | {
      name: "JOB_OFFER_ACCEPTED"
      data: {
        username: string
        jobOfferId: number
        schoolId: number
      }
    }
  | {
      name: "JOB_OFFER_REJECTED"
      data: {
        jobPosition: string
        jobId: number
      }
    }

export const defaultProfile: Profile = {
  id: 0,
  name: "",
  slug: "",
  branch: null,
  website: "",
  city: null,
  pincode: null,
  email: "",
  phoneNumber: null,
  address: "",
  description: "",
  logo: "",
  coverImage: "",
  dateEstablished: "",
  workingDays: null,
  dayStartTime: "",
  dayEndTime: "",
  teachingMode: TeachingMode.BLENDED,
  isVerified: false,
  managementMessage: null,
  curriculumBoard: [],
  galleryCount: 0,
  gallery: [],
  perks: [],
  amenities: [],
  state: null,
  country: null,
}
