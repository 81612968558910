import { WorkExperienceType } from "../teacher/types"
import { PhoneNumber } from "../users/types"

export enum UserReferenceRelationship {
  YOU_MANAGED_THEM_DIRECTLY = "you_managed_them_directly",
  YOU_REPORTED_DIRECTLY_TO_THEM = "you_reported_directly_to_them",
  YOU_WERE_SENIOR_TO_THEM_BUT_DID_NOT_MANAGE_THEM_DIRECTLY = "you_were_senior_to_them_but_did_not_manage_them_directly",
  THEY_WERE_SENIOR_TO_YOU_BUT_DID_NOT_MANAGE_YOU_DIRECTLY = "they_were_senior_to_you_but_did_not_manage_you_directly",
  YOU_WORKED_WITH_THEM_IN_THE_SAME_GROUP = "you_worked_with_them_in_the_same_group",
  THEY_WORKED_WITH_YOU_IN_A_DIFFERENT_GROUP = "they_worked_with_you_in_a_different_group",
  THEY_WORKED_WITH_YOU_BUT_AT_A_DIFFERENT_COMPANY = "they_worked_with_you_but_at_a_different_company",
  THEY_WERE_A_CLIENT_OF_YOURS = "they_were_a_client_of_yours",
  YOU_WERE_THEIR_CLIENT = "you_were_their_client",
  YOU_TAUGHT_THEM = "you_taught_them",
  YOU_MENTORED_THEM = "you_mentored_them",
  THEY_STUDIED_TOGETHER_WITH_YOU = "they_studied_together_with_you",
}

interface GenerateReferenceRelationshipChoicesProps {
  username: string
  referrerName: string
}
export const generateReferenceRelationshipChoices = ({
  username,
  referrerName,
}: GenerateReferenceRelationshipChoicesProps) => {
  const baseChoices = [
    {
      label: `${username} managed ${referrerName} directly`,
      value: UserReferenceRelationship.YOU_MANAGED_THEM_DIRECTLY,
    },
    {
      label: `${username} reported directly to ${referrerName}`,
      value: UserReferenceRelationship.YOU_REPORTED_DIRECTLY_TO_THEM,
    },
    {
      label: `${username} was senior to ${referrerName} but did not manage ${referrerName} directly`,
      value:
        UserReferenceRelationship.YOU_WERE_SENIOR_TO_THEM_BUT_DID_NOT_MANAGE_THEM_DIRECTLY,
    },
    {
      label: `${referrerName} was senior to ${username} but did not manage ${username} directly`,
      value:
        UserReferenceRelationship.THEY_WERE_SENIOR_TO_YOU_BUT_DID_NOT_MANAGE_YOU_DIRECTLY,
    },
    {
      label: `${username} worked with ${referrerName} in the same group`,
      value: UserReferenceRelationship.YOU_WORKED_WITH_THEM_IN_THE_SAME_GROUP,
    },
    {
      label: `${referrerName} worked with ${username} in a different group`,
      value:
        UserReferenceRelationship.THEY_WORKED_WITH_YOU_IN_A_DIFFERENT_GROUP,
    },
    {
      label: `${referrerName} worked with ${username} but at a different company`,
      value:
        UserReferenceRelationship.THEY_WORKED_WITH_YOU_BUT_AT_A_DIFFERENT_COMPANY,
    },
    {
      label: `${referrerName} was a client of ${username}`,
      value: UserReferenceRelationship.THEY_WERE_A_CLIENT_OF_YOURS,
    },
    {
      label: `${username} was ${referrerName}'s client`,
      value: UserReferenceRelationship.YOU_WERE_THEIR_CLIENT,
    },
    {
      label: `${username} taught ${referrerName}`,
      value: UserReferenceRelationship.YOU_TAUGHT_THEM,
    },
    {
      label: `${username} mentored ${referrerName}`,
      value: UserReferenceRelationship.YOU_MENTORED_THEM,
    },
    {
      label: `${referrerName} studied together with ${username}`,
      value: UserReferenceRelationship.THEY_STUDIED_TOGETHER_WITH_YOU,
    },
  ]

  return baseChoices
}

export enum UserQuestionnaireStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  SUBMITTED = 3,
}

export enum QuestionType {
  STRING = "string",
  TEXT = "text",
  DATE = "date",
  SINGLE_SELECT = "single_select",
}

export type Reference = {
  id: string
  referrer: Referrer
  relationship: UserReferenceRelationship
  source: Source
  userId: string
  userPosition: string
  organisationName: string
  startDate: string
  endDate: string
  sentAt: string
  userQuestionnaire: UserQuestionnaire
  message: string
}

export type Referrer = {
  name: string
  email: string
  phoneNumber: PhoneNumber
  position: string
}

export type UserQuestionnaire = {
  id: string | null
  submittedAt: string
  averageScore: string
  user: UserDetails
  status: UserQuestionnaireStatus
  sections: Section[]
  personalisation: Personalisation
}

export type Source = {
  id: number
  type: string
} & Omit<WorkExperienceType, "id">

export type Section = {
  id: string
  name: string
  description: string
  slug: string
  questions: Question[]
}

export type Question = {
  questionType: QuestionType
  id: string
  helpText: string
  name: string
  options: Option[]
  validations: Validation | null
  response: any | null
}

export type Option = {
  title: string
  description: string | null
  value: any
}

export type Validation = {
  minValue: any | null
  maxValue: any | null
  minLength: number | null
  maxLength: number | null
  required: boolean
}

export type UserDetails = {
  uuid: string | null
  name: string | null
  email: string | null
}

export type Personalisation = {
  refereeName: string
}
