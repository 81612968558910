import { Divider, Typography } from "@suraasa/placebo-ui"
import Section from "components/Section"
import { useContext } from "react"
import { pluralize } from "utils/helpers"
import ProfileContext from "views/teacher/profileContext"

import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import { getRelationshipLabel, ViewAnalysisCard } from "./Utils"

const ReferenceSection = () => {
  const { references, profile } = useContext(ProfileContext)

  const directReferences = references.data.filter(ref => {
    return ref.source === null
  })

  return (
    <>
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              itemCount={directReferences?.length}
              title={pluralize(
                "Additional Reference",
                directReferences?.length ?? 0,
                {
                  skipCount: true,
                }
              )}
            />
          }
          xPadding={2}
        />
        <SectionContent className="flex flex-col" xPadding={2}>
          {directReferences.length === 0 && (
            <Typography color="onSurface.400" variant="smallBody">
              Looks like there's nothing here.
            </Typography>
          )}
          {directReferences.map((data, index) => (
            <>
              <div className="group relative " key={index}>
                <div>
                  <Typography className="mb-1.5" variant="strong">
                    {data.referrer.name}
                  </Typography>
                  <Typography
                    color="onSurface.600"
                    className="mb-0.5"
                    variant="smallBody"
                  >
                    {data.referrer.position},{" "}
                    {getRelationshipLabel({
                      referrerName: data.referrer.name,
                      username: profile.user.firstName,
                      relationshipValue: data.relationship,
                    })}
                    <Typography variant="smallBody">
                      {data.organisationName}
                    </Typography>
                  </Typography>
                </div>
                <div className="mt-2">
                  <ViewAnalysisCard
                    reference={data}
                    rating={data.userQuestionnaire.averageScore}
                  />
                </div>
              </div>
              {index < directReferences.length - 1 && (
                <Divider
                  className="my-2.25"
                  color="onSurface.200"
                  weight="light"
                />
              )}
            </>
          ))}
        </SectionContent>
      </Section>
    </>
  )
}

export default ReferenceSection
